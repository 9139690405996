import { Action, createReducer, on } from '@ngrx/store';
import { Ship, ReportFormInterface, Report, DebugLog } from 'src/types';
import {
  loadReports,
  loadShip,
  createReport,
  updateReport,
  loadReportsFailure,
  loadShipFailure,
  createReportFailure,
  updateReportFailure,
  loadReportsSuccess,
  loadShipSuccess,
  setSelectedBunkerTypes,
  setCpid,
  setReportState,
  setSelectedReport,
  setDebugLog,
  updateCurrentReport,
} from './reporting.actions';

export interface State {
  reports: Report[];
  localReports: ReportFormInterface[];
  currentReport: ReportFormInterface;
  currentShip: Ship;
  selectedBunkerTypes: string[];
  error: any;
  log: DebugLog;
  loading: boolean;
}

export const initialState: State = {
  reports: [],
  localReports: [] as ReportFormInterface[],
  currentReport: {} as ReportFormInterface,
  currentShip: {} as Ship,
  selectedBunkerTypes: [],
  error: null,
  log: {} as DebugLog,
  loading: false,
};

export const reducerFn = createReducer(
  initialState,
  on(loadReports, loadShip, createReport, updateReport, (state) => {
    return { ...state, loading: true };
  }),
  on(loadReportsFailure, loadShipFailure, createReportFailure, updateReportFailure, (state, { error }) => {
    return { ...state, error, loading: false };
  }),
  on(loadReportsSuccess, (state, { reports }) => {
    return { ...state, localReports: reports, loading: false };
  }),
  on(loadShipSuccess, (state, { ship }) => {
    const currentShip = { ...state.currentShip, ...ship };
    return { ...state, currentShip, loading: false };
  }),
  on(setReportState, (state, { reports }) => {
    return { ...state, localReports: reports, loading: false };
  }),
  on(setSelectedBunkerTypes, (state, { selectedBunkerTypes }) => {
    return { ...state, selectedBunkerTypes, loading: false };
  }),
  on(setSelectedReport, (state, { localId, reportType }) => {
    const { localReports } = state;
    const timezone = localReports.length ? localStorage.getItem('timezone') || '' : '';

    const currentReport =
      localId === 'new'
        ? ({ id: localId, reportType, timezone, reportDate: new Date() } as ReportFormInterface)
        : (state.localReports.find(({ localId: reportId }) => reportId === localId) as ReportFormInterface);
    return { ...state, currentReport };
  }),
  on(setCpid, (state, { cpid }) => {
    const currentShip = cpid ? { ...state.currentShip, cpid } : { ...state.currentShip };
    return { ...state, currentShip, loading: false };
  }),
  on(updateCurrentReport, (state, { report }) => {
    return { ...state, currentReport: { ...state.currentReport, ...report } };
  }),
  on(setDebugLog, (state, { log }) => {
    return { ...state, log, loading: false };
  })
);

export function reducer(state = initialState, action: Action): State {
  return reducerFn(state, action);
}

export const getReports = (state: State) => ({ db: state.reports, local: state.localReports });
export const getLocalReports = (state: State) => state.localReports;
export const getShip = (state: State) => state.currentShip;
export const getLoading = (state: State) => state.loading;
export const getSelectedBunkerTypes = (state: State) => state.selectedBunkerTypes;
