import { ApplicationRef, Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { concat, interval, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { promptNewVersion } from './core/store/core.actions';
import { routeToLastShip } from './core/store/reporting/reporting.actions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  appUpdateSubscription!: Subscription;
  everySixHoursCheckSubscription!: Subscription;
  title = 'siglar-reporting-form';

  constructor(private store: Store, private appRef: ApplicationRef, private updates: SwUpdate) {}

  ngOnInit() {
    const uuid = localStorage.getItem('lastShipUuid') || '';
    this.store.dispatch(routeToLastShip({ uuid }));
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    this.everySixHoursCheckSubscription = everySixHoursOnceAppIsStable$.subscribe(() => {
      if (!isDevMode()) {
        this.updates.checkForUpdate();
      }
    });
    this.appUpdateSubscription = this.updates.available.subscribe(({ available }) =>
      this.store.dispatch(promptNewVersion({ version: available.hash }))
    );
  }

  ngOnDestroy(): void {
    if (this.everySixHoursCheckSubscription) {
      this.everySixHoursCheckSubscription.unsubscribe();
    }
    if (this.appUpdateSubscription) {
      this.appUpdateSubscription.unsubscribe();
    }
  }
}
