import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { ApmService } from '@elastic/apm-rum-angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { selectDebug } from '../store';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.store
      .select(selectDebug)
      .pipe(
        tap((isDebug) => {
          if (isDebug) {
            // TODO: `add logger`
            // const transaction = this.apmService.apm.getCurrentTransaction();
            // const log = { request, transaction: JSON.stringify(transaction) };
            // this.store.dispatch(setDebugLog({ log }));
          }
        }),
        take(2)
      )
      .subscribe();

    return next.handle(request);
  }
}
