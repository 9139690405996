import { ActionReducerMap, ActionReducer, MetaReducer, createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromCore from './core.reducer';
import * as fromRouter from '@ngrx/router-store';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface State {
  core: fromCore.State;
  router: fromRouter.RouterReducerState<any>;
}

export const reducers: ActionReducerMap<State> = {
  core: fromCore.reducer,
  router: fromRouter.routerReducer,
};

export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState>('router');
export const selectRouterState = createSelector(selectRouter, (state) => state);

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectRouteNestedParams = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

export const selectRouteNestedParam = (param: string) =>
  createSelector(selectRouteNestedParams, (params) => params && params[param]);

export const selectRouterUrl = createSelector(selectRouterState, (router) => router?.state?.url);

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{ reporting: ['reports', 'localReports', 'currentReport', 'error', 'selectedBunkerTypes'] }],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = !environment.production
  ? [debug, localStorageSyncReducer]
  : [localStorageSyncReducer];

export const selectCoreState = createFeatureSelector<fromCore.State>(fromCore.featureName);
export const selectCore = createSelector(selectCoreState, (state) => state);
export const getCoreState = createSelector(selectCore, fromCore.getCoreState);

export const selectNetwork = createSelector(getCoreState, ({ online }) => ({ online }));
export const selectDebug = createSelector(getCoreState, ({ isDebug }) => isDebug);
