import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { formatReportForRequest } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';
import { getLocalReports, getShip, selectReports } from '.';
import { ReportingService } from '../../services/reporting.service';
import { getCoreState, selectNetwork } from './../index';
import * as fromReporting from './reporting.actions';
import { CoreService } from '../../services/core.service';
import { ReportFormInterface } from 'src/types';

@Injectable({ providedIn: 'root' })
export class ReportingEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private reportingService: ReportingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private coreService: CoreService
  ) { }

  loadShip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.loadShip),
      concatLatestFrom(() => this.store.select(getCoreState)),
      switchMap(([_, { shipParameter, online }]) => {
        return online
          ? this.reportingService.getShip(shipParameter).pipe(
            map(({ data }) => fromReporting.loadShipSuccess({ ship: data[0] })),
            catchError((error) => of(fromReporting.loadShipFailure({ error })))
          )
          : this.reportingService.getShipLocally(shipParameter).pipe(
            map((ship) => {
              if (ship) {
                return fromReporting.loadShipSuccess({ ship });
              } else {
                return fromReporting.loadShipOfflineFailure();
              }
            }),
            catchError((error) => of(fromReporting.loadShipFailure({ error })))
          );
      })
    );
  });

  loadShipSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.loadShipSuccess),
      map(({ ship }) => {
        const { imo } = ship;
        this.reportingService.saveShipLocally(ship);
        return fromReporting.loadReports({ imo });
      })
    );
  });

  loadShipOfflineFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.loadShipOfflineFailure),
        tap(() => {
          this.coreService.showSnackbar(
            'warning',
            'Offline Notice: Could not retrieve ship',
            'The last reported ship will be stored locally and can be used while offline, but only after it has been retireved once. Please go online so the ship can be stored locally and be ready for use in offline mode.'
          );
        })
      );
    },
    { dispatch: false }
  );

  setSelectedReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.setSelectedReport),
      concatLatestFrom(() => this.store.select(selectReports)),
      concatMap(([{ localId }, reports]) => {
        let selectedBunkerTypes: string[] = [];
        if (localId === 'new') {
          // If new report, use previous reports bunkers
          if (reports.length) {
            selectedBunkerTypes = reports[0].bunkers.map(({ grade }) => grade);
          }
        } else {
          // If existing report, use current reports bunkers
          const currentReport = reports.find(({ localId: reportId }) => reportId === localId) as ReportFormInterface;
          selectedBunkerTypes = currentReport.bunkers.map(({ grade }) => grade);
        }
        return [
          fromReporting.setSelectedBunkerTypes({ selectedBunkerTypes }),
          fromReporting.routeToReport({ localId }),
        ];
      })
    );
  });

  routeToReport$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.routeToReport),
        concatLatestFrom(() => this.store.select(getShip)),
        map(([{ localId, reportType: type }, { uuid }]) => {
          this.router.navigate(['', uuid, localId], {
            queryParams: { type },
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',
          });
        })
      );
    },
    { dispatch: false }
  );

  routeToLastShip$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.routeToLastShip),
        concatLatestFrom(() => this.store.select(getShip)),
        map(([{ uuid }, ship]) => {
          const path = window.location.pathname;
          if (path === '/' && uuid) {
            this.router.navigate([uuid], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
          } else if (ship && Object.keys(ship).length !== 0) {
            this.router.navigate([ship?.uuid], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
          }
        })
      );
    },
    { dispatch: false }
  );

  routeToNewReport$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.routeToNewReport),
        concatLatestFrom(() => this.store.select(getShip)),
        map(([{ reportType: type }, { uuid }]) => {
          this.router.navigate(['', uuid, 'new'], {
            queryParams: { type },
          });
        })
      );
    },
    { dispatch: false }
  );

  loadReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.loadReports),
      concatLatestFrom(() => this.store.select(getCoreState)),
      switchMap(([{ imo }]) => {
        return this.reportingService.getReports(imo).pipe(
          map((reports) => {
            const newReports = reports.map((report) => (report.localId ? report : { ...report, localId: uuidv4() }));
            this.reportingService.setReportsLocally(newReports, imo);
            return fromReporting.loadReportsSuccess({ reports: newReports });
          }),
          catchError(({ error }) => of(fromReporting.loadReportsFailure({ error })))
        );
      })
    );
  });

  loadReportsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.loadReportsSuccess),
      mergeMap(({ reports }) => {
        return of(reports).pipe(
          mergeMap((innerReports) => {
            const actions = innerReports.map((report) => fromReporting.syncReport({ report }));
            return actions;
          })
        );
      })
    );
  });

  createReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.createReport),
      withLatestFrom(this.store.select(getLocalReports), this.store.select(getShip)),
      concatMap(([{ report }, localReports, { imo }]) => {
        // new report must be flagged with not synced
        this.reportingService.saveReportLocally(localReports, report, imo);
        const hasMissingId = report.bunkers && report.bunkers.some((bunker) => !('id' in bunker));
        if (hasMissingId) {
          return of(fromReporting.lockReport({ report }));
        }
        return of(fromReporting.syncReport({ report }));
      })
    );
  });

  updateReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.updateReport),
      concatMap(({ report }) => {
        const hasMissingId = report.bunkers && report.bunkers.some((bunker) => !('id' in bunker));
        if (hasMissingId) {
          return of(fromReporting.lockReport({ report }));
        }
        return of(fromReporting.syncReport({ report }))
      })
    );
  });

  syncReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.syncReport),
      withLatestFrom(this.store.select(getShip), this.store.select(selectNetwork)),
      concatMap(([{ report }, { uuid, imo }, { online }]) => {
        const hasMissingId = report.bunkers && report.bunkers.some((bunker) => !('id' in bunker));
        if (hasMissingId) {
          return of(fromReporting.syncReportNoop());
        }

        if (online) {
          const reportNotSynced = report.synced !== undefined && !report.synced;
          if (reportNotSynced) {
            const formattedReport = formatReportForRequest(report);
            return report.id && report.id !== 'new'
              ? this.reportingService.updateReport(formattedReport, uuid, report.id).pipe(
                map((res) => {
                  const ciiCorrectionFactors = res.data[0].ciiCorrectionFactors;
                  const bunkers = res.data[0].bunkers;
                  return fromReporting.syncReportSuccess({ report, imo, ciiCorrectionFactors, bunkers });
                }),
                catchError((response) => {
                  const { status } = response;
                  return status === 404
                    ? of(fromReporting.syncReport({ report: { ...report, id: 'new' } }))
                    : of(fromReporting.syncReportFailure({ response, report, imo }));
                })
              )
              : this.reportingService.createReport(formattedReport, uuid).pipe(
                map((response) => {
                  const ciiCorrectionFactors = response.data.ciiCorrectionFactors;
                  const bunkers = response.data.bunkers;
                  const id = response.id;
                  return fromReporting.syncReportSuccess({
                    report: { ...report, id },
                    imo,
                    ciiCorrectionFactors,
                    bunkers,
                  });
                }),
                catchError((response) => of(fromReporting.syncReportFailure({ response, report, imo })))
              );
          }
          return of(fromReporting.syncReportNoop());
        }
        return of(fromReporting.syncReportWarning({ report, imo }));
      })
    );
  });

  syncReportNoop$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(fromReporting.syncReportNoop));
    },
    { dispatch: false }
  );

  syncReportSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.syncReportSuccess),
        concatLatestFrom(() => this.store.select(getLocalReports)),
        tap(([{ report, imo, bunkers }, reports]) => {
          const copyOfReport = JSON.parse(JSON.stringify(report)) as ReportFormInterface;
          if (bunkers) {
            bunkers.forEach((item) => {
              copyOfReport.bunkers.forEach((bunker) => {
                if (item.grade === bunker.grade) {
                  bunker.id = item.id;
                }
              });
            });
          }

          const updatedReport = { ...copyOfReport, synced: true };
          this.reportingService.saveReportLocally(reports, updatedReport, imo);
          const reportDate = new Date(report.reportDate as Date);
          this.coreService.showSnackbar(
            'success',
            'Report Synced',
            `Report from ${reportDate.toLocaleDateString()} ${report.localTime} was successfully saved`
          );
        })
      );
    },
    { dispatch: false }
  );

  syncReportWarning$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.syncReportWarning),
        concatLatestFrom(() => this.store.select(getLocalReports)),
        tap(([{ report, imo }, reports]) => {
          const updatedReport = { ...report, synced: false };
          this.reportingService.saveReportLocally(reports, updatedReport, imo);
          this.coreService.showSnackbar(
            'warning',
            'Sync Notice',
            'You are currently offline. Your reports will sync with the server once you are online again.'
          );
        })
      );
    },
    { dispatch: false }
  );

  syncReportFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.syncReportFailure),
      concatLatestFrom(() => this.store.select(getLocalReports)),
      exhaustMap(([{ response, report, imo }, reports]) => {
        const {
          error: { error },
          status,
        } = response;
        const { id, reportDate } = report;
        const fields = Object.keys(report);
        const probableHandshakeDuplicate = id === 'new' && status === 409;

        if (probableHandshakeDuplicate) {
          const match = error.toString().match(/\d+/g);
          const lostId = parseInt(match?.[0] ?? '0');
          const reportWithId = { ...report, id: lostId };
          this.reportingService.saveReportLocally(reports, reportWithId, imo);
          return of(fromReporting.updateReport({ report: reportWithId, id: lostId, imo }));
        }

        let invalidValueMessage = error;

        for (const field of fields) {
          invalidValueMessage = error?.includes(field)
            ? `You have entered an invalid value for ${report[field as keyof typeof report]}.`
            : invalidValueMessage;
        }

        if (!error.includes('Cannot update a report of Verified voyage')) {
          const updatedReport = { ...report, id, message: invalidValueMessage };
          this.reportingService.saveReportLocally(reports, updatedReport, imo);
        } else {
          this.reportingService.setSynced(reports, id, imo);
          invalidValueMessage = error;
        }

        const reportDateString = new Date(reportDate as Date).toLocaleDateString();
        let message = `Synchronization of report from ${reportDateString} ${report.localTime} failed. ${invalidValueMessage}`;

        if (response?.error?.errorsList?.length) {
          message = `Synchronization of report from ${reportDateString} ${report.localTime} failed.
          ${response.error.errorsList[0]}`;
        }

        this.coreService.showSnackbar('danger', 'Report Sync Failed', message, {
          duration: 10_000,
        });
        return of(fromReporting.syncReportNoop());
      })
    );
  });

  upsertReportSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.createReportSuccess, fromReporting.updateReportSuccess),
      map(({ report, imo }) => {
        return fromReporting.saveReportLocally({ report, imo });
      })
    );
  });

  saveReportLocally$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.saveReportLocally),
      concatLatestFrom(() => this.store.select(getLocalReports)),
      map(([{ report, imo }, localReports]) => {
        this.reportingService.saveReportLocally(localReports, report, imo);
        return fromReporting.loadReports({ imo });
      })
    );
  });

  saveSelectedBunkersLocally$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.saveSelectedBunkersLocally),
      concatLatestFrom(() => this.store.select(getShip)),
      map(([{ selectedBunkers: selectedBunkerTypes }, { uuid }]) => {
        this.router.navigate(['', uuid]);
        this.reportingService.saveSelectedBunkersLocally(selectedBunkerTypes);
        return fromReporting.setSelectedBunkerTypes({ selectedBunkerTypes });
      })
    );
  });

  loadSelectedBunkers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.loadSelectedBunkers),
      map(() => this.reportingService.getSelectedBunkers())
    );
  });

  saveTimezoneLocally$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.saveTimezoneLocally),
      map(({ timezone }) => {
        this.reportingService.saveTimezoneLocally(timezone);
        return fromReporting.setTimezone({ timezone });
      })
    );
  });

  saveCpidLocally$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReporting.saveCpidLocally),
      map(({ cpid }) => {
        return fromReporting.setCpid({ cpid });
      })
    );
  });

  clearShip$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.clearShip),
        map(({ imo }) => {
          this.reportingService.removeLastUsedUuid();
          this.reportingService.removeShipReports(imo);
          this.router.navigate(['/']);
        })
      );
    },
    { dispatch: false }
  );

  success$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.createReportSuccess, fromReporting.updateReportSuccess),
        tap(({ type }) => this.coreService.showSnackbar('success', type, 'The operation was successful!'))
      );
    },
    { dispatch: false }
  );

  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          fromReporting.loadShipFailure,
          fromReporting.loadReportsFailure,
          fromReporting.createReportFailure,
          fromReporting.updateReportFailure
        ),
        tap(({ type, error }) => {
          const message = error && error.error ? JSON.stringify(error.error) : 'Something went wrong';
          this.coreService.showSnackbar('danger', type, message);
        })
      );
    },
    { dispatch: false }
  );

  lockReport$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReporting.lockReport),
        tap(({ report }) => {
          const reportDate = new Date(report.reportDate as Date);
          return this.coreService.showSnackbar(
            'warning',
            'Sync Notice',
            `The ${report.reportType} report on ${reportDate.toLocaleDateString()} ${report.localTime} has been marked as read-only. Updates cannot be made by the captain.`
          )
        }
        )
      );
    },
    { dispatch: false }
  );
}
