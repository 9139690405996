import { Action, createReducer, on } from '@ngrx/store';
import { initialize, setDebug, setOnlineStatus } from './core.actions';

export interface State {
  shipParameter: string;
  online: boolean;
  isDebug: boolean;
}

export const initalState: State = {
  shipParameter: '',
  online: navigator.onLine,
  isDebug: false,
};

export const reducerFn = createReducer(
  initalState,
  on(initialize, (state, { name: shipParameter }) => {
    return { ...state, shipParameter };
  }),
  on(setOnlineStatus, (state, { online }) => {
    return { ...state, online };
  }),
  on(setDebug, (state, { isDebug }) => {
    return { ...state, isDebug };
  })
);

export function reducer(state = initalState, action: Action): State {
  return reducerFn(state, action);
}

export const getCoreState = (state: State) => state;

export const featureName = 'core';
