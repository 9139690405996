import { createAction, props } from '@ngrx/store';

export const enum CoreActions {
  Initialize = '[CORE] Initialize',
  SetOnlineStatus = '[CORE] Set Online Status',
  SetDebug = '[CORE] Set Debug',
  PromptNewVersion = '[CORE] Prompt new version',
  CheckForUpdate = '[CORE] Check for update',
}

export const initialize = createAction(CoreActions.Initialize, props<{ name: string }>());
export const setOnlineStatus = createAction(CoreActions.SetOnlineStatus, props<{ online: boolean }>());
export const setDebug = createAction(CoreActions.SetDebug, props<{ isDebug: boolean }>());
export const promptNewVersion = createAction(CoreActions.PromptNewVersion, props<{ version: string }>());
export const checkForUpdate = createAction(CoreActions.CheckForUpdate);
