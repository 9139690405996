import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as fromRoot from './core.actions';
import { loadShip, syncReportNoop } from './reporting/reporting.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class CoreEffects {
  constructor(private actions$: Actions, private updates: SwUpdate, private snackBar: MatSnackBar) {}

  initialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromRoot.initialize),
      map(() => {
        return loadShip();
      })
    );
  });

  setOnlineStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromRoot.setOnlineStatus),
      map(({ online }) => (online ? loadShip() : syncReportNoop()))
    );
  });

  checkForUpdate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromRoot.checkForUpdate),
        tap(() => this.updates.checkForUpdate())
      );
    },
    { dispatch: false }
  );

  promptNewVersion$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromRoot.promptNewVersion),
        tap(() => {
          const message = `New version available\n\nOur web form has an update waiting. Please reload the page as soon as possible to fetch the update.`;
          const snackBarRef = this.snackBar.open(message, 'Update', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 7_000,
            panelClass: ['snackbar-success'],
          });
          snackBarRef.onAction().subscribe(() => {
            document.location.reload();
          });
        })
      );
    },
    { dispatch: false }
  );
}
