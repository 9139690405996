import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class CoreService {
  constructor(private snackBar: MatSnackBar) {}

  showSnackbar(action: 'success' | 'warning' | 'danger', title: string, text = '', config: MatSnackBarConfig = {}) {
    let message = title;
    if (text !== '') {
      message = `${message}\n\n${text}`;
    }
    this.snackBar.open(message, '✕', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 7_000,
      panelClass: [`snackbar-${action}`],
      ...config,
    });
  }
}
