import { createAction, props } from '@ngrx/store';
import { DebugLog, ReportFormInterface, ReportType } from 'src/types';

export const enum ReportingActions {
  LoadReports = '[REPORTING] Load Reports',
  LoadReportsSuccess = '[REPORTING] Load Reports Success',
  LoadReportsFailure = '[REPORTING] Load Reports Failure',
  RouteToLastShip = '[REPORTING] Route To Last Ship',
  RouteToNewReport = '[REPORTING] Route To New Report',
  RouteToReport = '[REPORTING] Route To Report',
  SetSelectedReport = '[REPORTING] Set Selected Report',
  LoadShip = '[REPORTING] Load Ship',
  LoadShipSuccess = '[REPORTING] Load Ship Success',
  LoadShipFailure = '[REPORTING] Load Ship Failure',
  LoadShipOfflineFailure = '[REPORTING] Load Ship Offline Failure',
  ClearShip = '[REPORTING] Clear Ship',
  CreateReport = '[REPORTING] Create Report',
  CreateReportSuccess = '[REPORTING] Create Report Success',
  CreateReportFailure = '[REPORTING] Create Report Failure',
  UpdateReport = '[REPORTING] Update Report',
  UpdateReportSuccess = '[REPORTING] Update Report Success',
  UpdateReportFailure = '[REPORTING] Update Report Failure',
  UpdateCurrentReport = '[REPORTING] Update Current Report',
  SaveReportLocally = '[REPORTING] Save Report Locally',
  LockReport = '[REPORTING] Locked Report',
  SyncReport = '[REPORTING] Sync Report',
  SyncReportNoop = '[REPORTING] Sync Report Noop',
  SyncReportSuccess = '[REPORTING] Sync Report Success',
  SyncReportFailure = '[REPORTING] Sync Report Failure',
  SyncReportWarning = '[REPORTING] Sync Report Warning',
  SetReportState = '[REPORTING] Set Report State',
  SaveSelectedBunkersLocally = '[REPORTING] Save Selected Bunkers Locally',
  SetSelectedBunkerTypes = '[REPORTING] Set Selected Bunker Types',
  LoadSelectedBunkers = '[REPORTING] Load Selected Bunkers',
  SaveTimezoneLocally = '[REPORTING] Save Timezone Locally',
  SetTimezone = '[REPORTING] Set Timezone',
  LoadTimezone = '[REPORTING] Load Timezone',
  SaveCpidLocally = '[REPORTING] Save Ship Uuid Locally',
  SetCpid = '[REPORTING] Set Ship Uuid',
  LoadCpid = '[REPORTING] Load Ship Uuid',
  SetDebugLog = '[REPORTING] Set Debug Log',
}

/** Routing */
export const routeToLastShip = createAction(ReportingActions.RouteToLastShip, props<{ uuid: string }>());
export const routeToNewReport = createAction(ReportingActions.RouteToNewReport, props<{ reportType: string }>());
export const routeToReport = createAction(
  ReportingActions.RouteToReport,
  props<{ localId: string; reportType?: ReportType }>()
);

/** Selected Report */
export const setSelectedReport = createAction(
  ReportingActions.SetSelectedReport,
  props<{ localId: string; reportType?: ReportType }>()
);

/** Load Reports */
export const loadReports = createAction(ReportingActions.LoadReports, props<{ imo: number }>());
export const loadReportsSuccess = createAction(
  ReportingActions.LoadReportsSuccess,
  props<{ reports: ReportFormInterface[] }>()
);
export const loadReportsFailure = createAction(ReportingActions.LoadReportsFailure, props<{ error: any }>());

/** Load Ship */
export const loadShip = createAction(ReportingActions.LoadShip);
export const loadShipSuccess = createAction(ReportingActions.LoadShipSuccess, props<{ ship: any }>());
export const loadShipOfflineFailure = createAction(ReportingActions.LoadShipOfflineFailure);
export const loadShipFailure = createAction(ReportingActions.LoadShipFailure, props<{ error: any }>());

/** Clear Ship */
export const clearShip = createAction(ReportingActions.ClearShip, props<{ imo: number }>());

/** Create Report */
export const createReport = createAction(ReportingActions.CreateReport, props<{ report: ReportFormInterface }>());
export const createReportSuccess = createAction(
  ReportingActions.CreateReportSuccess,
  props<{ report: ReportFormInterface; imo: number }>()
);
export const createReportFailure = createAction(ReportingActions.CreateReportFailure, props<{ error: any }>());

/** Update Report */
export const updateReport = createAction(
  ReportingActions.UpdateReport,
  props<{ report: ReportFormInterface; imo: number; id: number | string }>()
);
export const updateReportSuccess = createAction(
  ReportingActions.UpdateReportSuccess,
  props<{ report: ReportFormInterface; imo: number }>()
);
export const updateReportFailure = createAction(ReportingActions.UpdateReportFailure, props<{ error: any }>());

/** Update Current Report */
export const updateCurrentReport = createAction(
  ReportingActions.UpdateCurrentReport,
  props<{ report: ReportFormInterface }>()
);

/** Save Report */
export const saveReportLocally = createAction(
  ReportingActions.SaveReportLocally,
  props<{ report: ReportFormInterface; imo: number }>()
);

/** Lock Report-readOnly*/
export const lockReport = createAction(ReportingActions.LockReport, props<{ report: ReportFormInterface }>());

/** Sync Report */
export const syncReport = createAction(ReportingActions.SyncReport, props<{ report: ReportFormInterface }>());
export const syncReportNoop = createAction(ReportingActions.SyncReportNoop);
export const syncReportSuccess = createAction(
  ReportingActions.SyncReportSuccess,
  props<{
    report: ReportFormInterface;
    imo: number;
    ciiCorrectionFactors: { id: string; grade: string }[];
    bunkers: { grade: string; id: number }[];
  }>()
);
export const syncReportFailure = createAction(
  ReportingActions.SyncReportFailure,
  props<{
    response: { status: number; error: { error: string; status: string; errorsList: string[] } };
    report: ReportFormInterface;
    imo: number;
  }>()
);
export const syncReportWarning = createAction(
  ReportingActions.SyncReportWarning,
  props<{ report: ReportFormInterface; imo: number }>()
);

/** Set Report State */
export const setReportState = createAction(
  ReportingActions.SetReportState,
  props<{ reports: ReportFormInterface[] }>()
);

/** Selected bunkers */
export const saveSelectedBunkersLocally = createAction(
  ReportingActions.SaveSelectedBunkersLocally,
  props<{ selectedBunkers: Array<string> }>()
);
export const setSelectedBunkerTypes = createAction(
  ReportingActions.SetSelectedBunkerTypes,
  props<{ selectedBunkerTypes: string[] }>()
);
export const loadSelectedBunkers = createAction(ReportingActions.LoadSelectedBunkers);

// Timezone actions
export const saveTimezoneLocally = createAction(ReportingActions.SaveTimezoneLocally, props<{ timezone: string }>());
export const setTimezone = createAction(ReportingActions.SetTimezone, props<{ timezone: string }>());

// CPID actions
export const saveCpidLocally = createAction(ReportingActions.SaveCpidLocally, props<{ cpid: string }>());
export const setCpid = createAction(ReportingActions.SetCpid, props<{ cpid: string }>());

/** Set debug log */
export const setDebugLog = createAction(ReportingActions.SetDebugLog, props<{ log: DebugLog }>());
