export const compare = (x: string, y: string, isAsc: boolean) => {
  return (x.toString().toLocaleLowerCase() < y.toString().toLocaleLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
};

export const compareNumber = (x: string | number, y: string | number, isAsc: boolean) => {
  return (x < y ? -1 : 1) * (isAsc ? 1 : -1);
};

export const compareDate = (date1: Date, date2: Date, isAsc: boolean) => {
  return (date1 < date2 ? -1 : 1) * (isAsc ? 1 : -1);
};
