import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateReportResponse, Report, ReportFormInterface, Ship, UpdateReportResponse } from 'src/types';
import { compareDate } from 'src/utils';
import { setReportState } from '../store/reporting/reporting.actions';
import packageJson from '../../../../package.json';

@Injectable({ providedIn: 'root' })
export class ReportingService {
  private readonly sourceVersion: string = packageJson.version;
  constructor(private http: HttpClient, private store: Store) { }

  getReports(imo: number): Observable<ReportFormInterface[]> {
    const imoString = imo.toString();
    let localReports = localStorage.getItem(imoString) || '[]';
    localReports = localReports.replaceAll('sailingSpeedKt', 'speedOverGroundKT');
    const reports = JSON.parse(localReports) as ReportFormInterface[];
    return of(reports);
  }

  createReport(report: Partial<Report>, uuid: string) {
    const reportWithVersion = { ...report, sourceVersion: this.sourceVersion };
    let url = `${environment.siglarApi.url}/api/v1/reports/${uuid}`;
    if (report.reportType === 'cosp') {
      url = `${environment.siglarApi.url}/api/v1/reports/${uuid}?bypassValidations=condition,speed`;
    }
    return this.http.post<CreateReportResponse>(url, reportWithVersion);
  }

  updateReport(report: Partial<Report>, uuid: string, id: string | number) {
    const reportWithVersion = { ...report, sourceVersion: this.sourceVersion };
    let url = `${environment.siglarApi.url}/api/v1/reports/${uuid}/${id}`;
    if (report.reportType === 'cosp') {
      url = `${environment.siglarApi.url}/api/v1/reports/${uuid}/${id}?bypassValidations=condition,speed`;
    }
    return this.http.put<UpdateReportResponse>(url, reportWithVersion);
  }

  setReportsLocally(reports: ReportFormInterface[], imo: number) {
    localStorage.setItem(imo.toString(), JSON.stringify(reports));
  }

  removeLastUsedUuid() {
    localStorage.removeItem('lastShipUuid');
  }

  removeShipReports(imo: any) {
    localStorage.removeItem(imo);
  }

  saveReportLocally(reports: ReportFormInterface[], report: ReportFormInterface, imo: number) {
    const reportExists = reports.some(({ localId }) => localId === report.localId);
    const newReports = reportExists
      ? reports.map((existingReport) => (existingReport.localId === report.localId ? report : existingReport))
      : [...reports, report];
    const sortedReports = newReports.sort((x: ReportFormInterface, y: ReportFormInterface) =>
      compareDate(new Date(x.reportDate as Date) || new Date(), new Date(y.reportDate as Date) || new Date(), false)
    );
    localStorage.setItem(imo.toString(), JSON.stringify(sortedReports));
    this.store.dispatch(setReportState({ reports: sortedReports }));
  }

  setSynced(reportsRecords: ReportFormInterface[], reportId: number | string | undefined, imo: number) {
    const report = reportsRecords.find(({ id }) => id === reportId);
    if (report === undefined) {
      return;
    }
    const reports = reportsRecords.map((existingReport) =>
      existingReport.localId === report.localId ? { ...report, synced: true } : existingReport
    ) as ReportFormInterface[];
    localStorage.setItem(imo.toString(), JSON.stringify(reports));
    this.store.dispatch(setReportState({ reports }));
  }

  saveShipLocally(ship: Ship) {
    localStorage.setItem(ship.uuid, JSON.stringify(ship));
    localStorage.setItem('lastShipUuid', ship.uuid);
  }

  saveSelectedBunkersLocally(bunkers: Array<string>) {
    const bunks = JSON.stringify(bunkers);
    localStorage.setItem('bunkers', bunks);
  }

  getSelectedBunkers() {
    const bunkersFromLocalStorage = localStorage.getItem('bunkers') || '[]';
    const bunkers = JSON.parse(bunkersFromLocalStorage);
    return bunkers;
  }

  saveTimezoneLocally(timezone: string) {
    localStorage.setItem('timezone', timezone);
  }

  getShip(shipCode: string) {
    const shipUuidUrl = new URL('/api/v1/ship_uuids', environment.siglarApi.url);
    return this.http.get<{ data: any[] }>(`${shipUuidUrl.href}/${shipCode}`);
  }

  getLastShipUuid() {
    const lastShipUuid = localStorage.getItem('lastShipUuid');
    return lastShipUuid;
  }

  getShipLocally(uuid: string) {
    const ship = localStorage.getItem(uuid) || 'null';
    return of(JSON.parse(ship));
  }
}
