import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportFormInterface } from 'src/types';
import { compareDate, setTimeAndTimezone } from 'src/utils';
import * as fromRoot from '..';
import * as fromReporting from './reporting.reducers';
import Decimal from 'decimal.js';

export interface State extends fromRoot.State {
  reporting: fromReporting.State;
}

export const selectReportingState = createFeatureSelector<fromReporting.State>('reporting');
export const selectReporting = createSelector(selectReportingState, (state) => state);

export const getLocalReports = createSelector(selectReporting, fromReporting.getLocalReports);

export const selectReports = createSelector(getLocalReports, (localReports) => {
  return localReports.map((report: ReportFormInterface, idx: number) => {
    const firstReportEntry = idx === localReports.length - 1;

    const { bunkers: previousBunkers } = firstReportEntry ? { bunkers: [] } : localReports[idx + 1];

    const bunkers = report.bunkers.map(({ grade, remainingOnboardMt, consumptionMt, receivedMt }) => {
      let calculatedConsumptionMt = 0;
      const previousBunker = previousBunkers.find(({ grade: bunkerType }) => bunkerType === grade);

      if (previousBunker && !firstReportEntry) {
        const calculatedDiff = new Decimal(Number(receivedMt ?? 0))
          .plus(new Decimal(Number(previousBunker.remainingOnboardMt)))
          .minus(new Decimal(Number(remainingOnboardMt)));
        calculatedConsumptionMt = calculatedDiff.toNumber();
      }

      return {
        grade,
        remainingOnboardMt,
        consumptionMt,
        receivedMt,
        calculatedConsumptionMt,
      };
    });

    return { ...report, bunkers };
  });
});

export const selectCurrentReport = createSelector(selectReportingState, ({ currentReport }) => currentReport);
export const selectPreviousReport = createSelector(selectReportingState, ({ currentReport, localReports }) => {
  let currentReportDate = new Date(currentReport.reportDate as Date);
  if (currentReport.localTime && currentReport.timezone) {
    currentReportDate = setTimeAndTimezone(currentReportDate, currentReport.localTime, currentReport.timezone);
  }
  return [...localReports]
    .map((report) => {
      return {
        ...report,
        reportDate: setTimeAndTimezone(new Date(report?.reportDate as Date), report.localTime, report.timezone),
      };
    })
    .sort(({ reportDate: a }, { reportDate: b }) => compareDate(a as Date, b as Date, false))
    .find(
      ({ reportDate, id }) =>
        new Date(reportDate as Date).getTime() < new Date(currentReportDate).getTime() && id !== currentReport.id
    );
});
export const selectSelectedBunkers = createSelector(selectReporting, fromReporting.getSelectedBunkerTypes);
export const selectError = createSelector(selectReporting, ({ error }) => error);
export const selectDebugLog = createSelector(selectReporting, ({ log }) => log);

export const getShip = createSelector(selectReporting, fromReporting.getShip);
export const getShipUuid = createSelector(getShip, (ship) => ship?.uuid);
export const getCpid = createSelector(getShip, (ship) => ship?.cpid);

export const { reducer: reportingReducer } = fromReporting;
