import { ReportingEffects } from './reporting.effects';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reportingReducer } from '.';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('reporting', reportingReducer),
    EffectsModule.forFeature([ReportingEffects]),
  ],
})
export class ReportingStoreModule {}
