// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appUrl: 'https://dev.reporting.api.siglarcarbon.com',
  siglarApi: {
    url: 'https://dev.reporting.api.siglarcarbon.com',
  },
  apm: {
    serviceName: 'siglar-reporting-form',
    transactionSampleRate: 1,
  },
};
