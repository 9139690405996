import { Report, ReportFormInterface, ReportType } from 'src/types';

export const formatReportForRequest = (report: ReportFormInterface): Partial<Report> => {
  const { bunkers = [], reportDate, localTime, timezone, port, nextCargoPort, previousCargoPort } = report;

  const formattedBunkers = bunkers.map(
    (bunker) => ({
      id: bunker.id ?? null,
      grade: bunker.grade,
      remainingOnboardMt: Number(bunker.remainingOnboardMt),
      consumptionMt: Number(bunker.consumptionMt),
      receivedMt: Number(bunker.receivedMt),
    }),
    {}
  );
  type ReportObjectKey = keyof typeof report;

  const numberProperties = ['sailedDistanceNm', 'speedOverGroundKT', 'latitude', 'longitude', 'cargoQuantityMt'];
  numberProperties.forEach((key) => {
    if (key in report) {
      report = { ...report, [key]: Number(report[key as ReportObjectKey]) };
    }
  });
  const reportDatified = new Date(reportDate as Date);
  const month = ('0' + (reportDatified.getMonth() + 1)).slice(-2);
  const day = ('0' + reportDatified.getDate()).slice(-2);
  const reportedTime = `${reportDatified.getFullYear()}-${month}-${day}T${localTime}:00${timezone}`;
  const allowedKeys = allowedKeysMap[report.reportType];
  const reportKeys = Object.keys(report) as (keyof ReportFormInterface)[];
  const filteredReport = reportKeys
    .filter((key) => key !== 'localId' && report[key] !== 'new' && (report[key] === 0 || !!report[key]))
    .filter((key) => allowedKeys.includes(key))
    .reduce((accum, key) => ({ ...accum, [key]: report[key] }), {});

  const newReport: Partial<Report> = {
    ...filteredReport,
    bunkers: formattedBunkers,
    reportedTime,
    utcOffset: timezone,
    source: 'siglar-web-form',
  };

  if (port) {
    newReport.port = port.unlocode;
  }
  if (nextCargoPort) {
    newReport.nextCargoPort = nextCargoPort.unlocode;
  }
  if (previousCargoPort) {
    newReport.previousCargoPort = previousCargoPort.unlocode;
  }


  if (newReport.reportType === 'noon' && newReport.location && newReport.location !== 'at_sea') {
    delete newReport.latitude;
    delete newReport.longitude;
    delete newReport.speedOverGroundKT;
  } else if (newReport.reportType === 'noon' && newReport.location && newReport.location === 'at_sea') {
    delete newReport.portActivity;
    delete newReport.port;
  }

  return newReport;
};

const allowedKeysMap: { [key in ReportType]: string[] } = {
  cast_off: [
    'bunkers',
    'cargoQuantityMt',
    'condition',
    'localTime',
    'location',
    'port',
    'nextCargoPort',
    'previousCargoPort',
    'remark',
    'reportDate',
    'reportedTime',
    'reportType',
    'sailedDistanceNm',
    'source',
    'timezone',
    'utcOffset',
  ],
  noon: [
    'bunkers',
    'latitude',
    'localTime',
    'location',
    'longitude',
    'port',
    'portActivity',
    'remark',
    'reportDate',
    'reportedTime',
    'reportType',
    'sailedDistanceNm',
    'speedOverGroundKT',
    'source',
    'timezone',
    'utcOffset',
  ],
  cosp: [
    'bunkers',
    'localTime',
    'latitude',
    'longitude',
    'port',
    'remark',
    'reportDate',
    'reportedTime',
    'reportType',
    'sailedDistanceNm',
    'speedOverGroundKT',
    'source',
    'timezone',
    'utcOffset',
  ],
  eosp: [
    'bunkers',
    'localTime',
    'latitude',
    'longitude',
    'port',
    'portActivity',
    'remark',
    'reportDate',
    'reportedTime',
    'reportType',
    'sailedDistanceNm',
    'speedOverGroundKT',
    'source',
    'timezone',
    'utcOffset',
  ],
  all_fast: [
    'bunkers',
    'localTime',
    'port',
    'portActivity',
    'location',
    'remark',
    'reportDate',
    'reportedTime',
    'reportType',
    'sailedDistanceNm',
    'speedOverGroundKT',
    'source',
    'timezone',
    'utcOffset',
  ],
};

export const setTimeAndTimezone = (date: Date, time: string, timezone: string): Date => {
  const [hours, minutes] = time.split(':');
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const dateInWithoutTimeZone = `${year}-${month}-${day}T${hours}:${minutes}:00Z`;
  const matchResult = timezone.match(/^([+-])(\d{2}):(\d{2})$/);
  const [timezoneSign, timezoneHours, timezoneMinutes] = matchResult?.slice(1) ?? ['', '', ''];
  const timeZoneOffsetMinutes =
    (timezoneSign === '+' ? 1 : -1) * (Number(timezoneHours) * 60 + Number(timezoneMinutes));
  const newTime = new Date(dateInWithoutTimeZone).getTime() + timeZoneOffsetMinutes * 60 * 1000;
  return new Date(newTime);
};
